import React, { useContext } from "react"
import { GlobalContext } from "../context/provider.js"
import { navigate } from "gatsby"
import CustomButton from "./button.js"
import Banner from "./banner"
import OurValues from "./about-us/ourValues"
import OurProcess from "./about-us/ourProcess"
import "./aboutUs.css"

const AboutUs = () => {
  const { dispatch } = useContext(GlobalContext)

  return (
    <div className="about-us">
      <Banner />
      <div className="about-us-body">
        <div className="about-us-body-header">
          <h1>
            Compare n Save, <br />
            it’s all in the <strong>name</strong>
          </h1>
          <p>
            Compare 100s of loans to save money. Our smart tech searches options
            from Australia’s leading lenders to help you find the right home
            loan. Our team of finance experts will guide you through every step
            of the process - from application right through to settlement. With
            a promise to always work in your best interest, you can make a
            decision with complete peace of mind.
          </p>
          <CustomButton
            variant="secondary"
            className="about-us-button"
            onClick={() => {
              dispatch({ type: "SET_REFINANCE", refinance: false })
              navigate("/product")
            }}
          >
            Try it out
          </CustomButton>
        </div>
        <OurValues />
        <OurProcess />
      </div>
    </div>
  )
}

export default AboutUs
