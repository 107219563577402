import React from "react"
import { Card, CardGroup } from "react-bootstrap"
import CustomButton from "../button"
import PrivacyIcon from "../../assets/about-us-privacy.svg"
import TransparencyIcon from "../../assets/about-us-transparency.svg"
import CommitmentIcon from "../../assets/about-us-commitment.svg"
import { navigate } from "gatsby"

import "./ourValues.css"

const OurValues = () => {
  return (
    <div className="our-values">
      <h2>Compare n Save keep it simple</h2>
      <p>
        We’ve spent more than two decades helping uncomplicate the home loan
        process for many happy clients. Choosing from thousands of home
        loan options offered by dozens of lenders means you can find the right
        loan for your individual needs. We don’t succeed unless you do, so we’ve
        made it as easy as possible for you to get started.
      </p>
      <CardGroup>
        <Card>
          <PrivacyIcon className="card-icon" />
          <Card.Body>
            <Card.Title>Privacy</Card.Title>
            <Card.Text>
              Select your preferred home loan option online and contact us to
              begin your application journey.
            </Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <TransparencyIcon className="card-icon" />
          <Card.Body>
            <Card.Title>Transparency</Card.Title>
            <Card.Text>
              Our service is provided to you at no cost, as we are remunerated
              by lenders upon the successful settlement and retention of your
              home loan. During your home loan journey, you’ll receive full
              details of how and what we are paid.
            </Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <CommitmentIcon className="card-icon" />
          <Card.Body>
            <Card.Title>Commitment</Card.Title>
            <Card.Text>
              We’ve streamlined the process to save you time, make it easier to
              find a home loan and stay informed throughout the process.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
      <CustomButton
        variant="secondary"
        className="our-values-button"
        onClick={() => {
          navigate("/contact")
        }}
      >
        Chat with an expert
      </CustomButton>
    </div>
  )
}

export default OurValues
